<template>
    <div class="container-fluid">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>{{ $t('general.nuevooperario') }}</h1>
                    </div>
                    <div class="col-sm-6">
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="row">                
                <div class="col-md-10">
                    <operario-datos2 :id="id" :operario="operario" />                                    
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi'
//import Dialog from 'primevue/dialog';
export default {
    props: ['id'],
    components: {
    },
    data() {
        return {
            operjefe:'',
            suboperselec:'',
            operario: [],
            operarios: [],
            codigoActivado:false,
            suboperarios: [],
            haysubos:null,
        }
    },
    methods: {
        eliminarjefe(){
            this.suboperselec = -1;
        },
        async obtenerOperario(id) {
            /* get  modulos/pwgsapi/index.php/operarios/:id */  
            this.operjefe = '';          
            const api = new PwgsApi;
            const resp = await api.get('operarios/simple');
            this.operarios = resp.datos;
            this.operarios = this.operarios.filter(operario=> operario.idoperario != this.id);
            const res = await api.get('parametros-pwgs/operario_zona_cp');
            if(res.operario_zona_cp==1)this.codigoActivado = true;
            const datos = await api.get('operarios/' + id);
            this.operario = datos.datos;
            //
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;
            let nombreoperario = this.operario.nombre;
            
            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana == '/operarios/' + ventanaid) {
                    this.$store.getters.ventanas[i].name = nombreoperario;
                    this.$store.getters.ventanas[i].alt = "Operario: " + this.operario.nombre + " || " + this.operario.poblacion_operario;
                }
            }
            this.obtenerSubOperarios();
            //
        },
        async obtenerSubOperarios(){
            const api = new PwgsApi;
            const resp = await api.get('operarios/'+this.id+'/suboperarios');
            if(resp.operario_principal !=''){                
                this.operjefe = this.operarios.find(operario=> operario.idoperario == resp.operario_principal);
                console.log('operje', this.operjefe, this.operarios);
            }else{
                if(resp.suboperarios.datos){
                    this.suboperarios = resp.suboperarios.datos;
                    this.haysubos = true;
                }else{
                    this.suboperselec = '-1';
                    this.haysubos = false;
                }
            }
            console.log('subops', this.suboperarios);
        },
        async cambiarSubOpeario(newval, oldval){
            const api = new PwgsApi;
            console.log('newv', newval);
            if(newval == '-1'){
                if(oldval !=''){
                    const resp = await api.delete('operarios/'+oldval+'/suboperarios/'+this.id);
                    console.log('resp', resp);
                }else{
                    if(this.operjefe !=''){
                        const resp = await api.delete('operarios/'+this.operjefe.idoperario+'/suboperarios/'+this.id);
                        console.log('respa', resp);
                        this.operjefe = '';
                    }
                    this.haysubos = false;
                    console.log('estabavacio');
                }
            }else{
                if(oldval !='-1'){
                    await api.delete('operarios/'+oldval+'/suboperarios/'+this.id);
                }
                await api.post('operarios/'+newval+'/suboperarios', {id_suboperario: this.id});
                this.operjefe = this.operarios.find(operario=> operario.idoperario == newval);
            }
        }
    },
    mounted() {
        this.obtenerOperario(this.id);
    },
    watch: {
        id() {
            this.obtenerOperario(this.id);
        },
        $route() {
            //
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;
            let nombreoperario = this.operario.nombre;
     
            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana == '/operarios/' + ventanaid) {
                    this.$store.getters.ventanas[i].name = nombreoperario;
                    this.$store.getters.ventanas[i].alt = "Operario: " + this.operario.nombre + " || " + this.operario.poblacion_operario;
                }
            }
            //
        },
        suboperselec(newval,oldval) {
            console.log('oldval', newval, oldval);
            this.cambiarSubOpeario(newval, oldval);
        }
    }
}
</script>
